import axios from 'axios';
import { IPaging } from '@/core/models/paging.interface';
import { IFormResult, IPageResult, IResult } from '@/core/models/results.interface';
import { normalizeUrlWithParameters } from '@/core/utils/utils';

export const httpClient = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? '' : `${process.env.VUE_APP_API_URL}`
});


class ApiHttpService {
    getWithPaging<TModel>(resource: string, paging?: IPaging, filter?: any) {
        const urlApi = normalizeUrlWithParameters(
            `/api${resource}`,
            paging,
            filter
        );

        return new Promise<IPageResult<TModel>>((resolve, reject) => {
            httpClient.get<IPageResult<TModel>>(urlApi)
                .then(({ data }) => resolve(data))
                .catch(error => reject(error));
        });
    }

    getWithPagingAndFilter<TModel>(resource: string, paging?: IPaging,qs?:string) {
        let urlApi = normalizeUrlWithParameters(
            `/api${resource}`,
            paging,
            null
        );

        urlApi += `&filtro=${qs}`;
        
        return new Promise<IPageResult<TModel>>((resolve, reject) => {
            httpClient.get<IPageResult<TModel>>(urlApi)
                .then(({ data }) => resolve(data))
                .catch(error => reject(error));
        });
    }

    get<TModel>(resource: string, headers?: any) {
        const baseUrlApi = `/api${resource}`;
        return new Promise<IResult<TModel>>((resolve, reject) => {
            httpClient.get<IResult<TModel>>(baseUrlApi, {
                headers: headers
            })
                .then(({ data }) => resolve(data))
                .catch(error => reject(error));
        });
    }

    getHtml(resource: string, headers?: any): Promise<string> {
        const baseUrlApi = `/api${resource}`;
        return new Promise<string>((resolve, reject) => {
            httpClient.get<string>(baseUrlApi, {
                headers: headers
            }).then(({ data }) => resolve(data as string)).catch(error => reject(error));
        });
    }


    post<TModel>(resource: string, body?: any, headers?: any) {
        const baseUrlApi = `/api${resource}`;
        return new Promise<IFormResult<TModel>>((resolve, reject) => {
            httpClient.post<IFormResult<TModel>>(baseUrlApi, body, {
                headers: headers
            }).then(({ data }) => resolve(data)).catch(error => reject(error));
        });
    }

    download(resource: string, fileName?: string) {
        const baseUrlApi = `/api${resource}`;
        return new Promise<boolean>((resolve, reject) => {
            httpClient.get(baseUrlApi, { responseType: "blob", })
                .then(response => {
                    const { data, status, headers } = response;
                    const contentDisposition = headers['content-disposition'] 

                    let name = `${fileName}`;
                    if (contentDisposition) {
                        const fileNameMatch = contentDisposition.split(';').filter((x:string) => !x.includes("attachment"));
                        if (fileNameMatch.length === 2)
                        name = fileNameMatch[0].replace('filename=','').replaceAll('"',"").trim();
                    }

                    if (status === 204) {
                        resolve(false);
                    } else {


                        const file = new Blob([data], { type: data.type });
                        const fileURL = URL.createObjectURL(file);
                        const a = document.createElement("a");
                        document.body.appendChild(a);
                        a.href = fileURL;
                        a.download = `${name}`;
                        a.click();
                        setTimeout(() => {
                            window.URL.revokeObjectURL(fileURL);
                            document.body.removeChild(a);
                        }, 0);

                    }

                    resolve(true);
                }).catch(error => reject(error));
        });
    }
}

export default new ApiHttpService();